(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:pollReport
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="components">
       <file name="index.html">
        <poll-report></poll-report>
       </file>
     </example>
   *
   */
  angular
    .module('components')
    .directive('pollReport', pollReport);

  function pollReport() {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'components/poll-report/poll-report-directive.tpl.html',
      replace: false,
      controllerAs: 'pollReport',
      controller: 'PollReportCtrl'
    };
  }
}());
